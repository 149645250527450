import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import Moment from "moment";
import {
  createMachine,
  getSoftwareVersions
} from "../../models/_machine";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import * as _ from 'lodash';
import {
  getDeploymentType,
  getLocations,
  getUsers,
} from "../../models/_location";
import { getBoardsList } from "../../models/_board";
import { getBlendersList } from "../../models/_blenders";
import { getCupHoldersList } from "../../models/_cupHolders";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const validationSchema = yup.object({
  // name: yup.string().required("Machine name is required"),
  serialNumber: yup.string().required("Serial number is required"),
  //macAddress: yup.string().required("Serial number is required"),
  // connectionStatus: yup.string().required("Connection status is required"),
  //softwareVersion: yup.number().required("Software Version is required"),
  deploymentDate: yup.date().required("Deployment date is required"),
  blenderId: yup.string().required("Blender is required"),
  boardId: yup.string().required("Board is required"),
});

const CreateMachine = () => {
  const navigate = useNavigate();
  const resetManager = React.useRef<any>(null);
  const resetBlender = React.useRef<any>(null);
  const resetSoftwareversion = React.useRef<any>(null);
  const resetBoard = React.useRef<any>(null);
  const resetCupHolder = React.useRef<any>(null);
  const resetDeployType = React.useRef<any>(null);
  const resetLocation = React.useRef<any>(null);
  const resetDeploymentDate = React.useRef<any>(null);
  const resetTrialEndDate = React.useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [managerDB, setManagerDB] = useState([]);
  const [deployTypeDB, setDeployTypeDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [managerName, setManagerName] = useState("");
  const [deployTypeName, setDeployTypeName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [deploymentTypeId, setDeploymentTypeId] = useState("ff74a26f-7a71-4466-8cc3-96f09a1ba46b"); // Deployment type new ready for deployment
  const [deployingPersonId, setDeployingPersonId] = useState(null);
  const [locationResult, setLocationResult] = useState<any>({});
  const [locationsData, setLocationsData] = useState<any>([]);
  const [blenderDB, setBlenderDB] = useState<any>([])
  const [boardDB, setBoardDB] = useState<any>([])
  const [cupHolderDB, setCupHolderDB] = useState<any>([])
  const [softwareVersionsDB, setSoftwareVersionsDB] = useState<any>([])
  const fetchData = async () => {
    try {
      const locationIdRecord = await getLocations();
      const locationIdResults: any = locationIdRecord?.data ? locationIdRecord?.data : [];
      setLocationsData(locationIdRecord);
      locationIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const deploymentTypeRecord = await getDeploymentType();
      const deploymentTypeResults: any = deploymentTypeRecord
        ? deploymentTypeRecord
        : [];
      deploymentTypeResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const deploymentManagerRecord = await getUsers();
      const deploymentManagerResults: any = deploymentManagerRecord
        ? deploymentManagerRecord
        : [];
      deploymentManagerResults.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const blenderRecord = await getBlendersList();
      const blenderResults: any = blenderRecord
        ? blenderRecord
        : [];

      blenderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const boardRecord = await getBoardsList();
      const boardResults: any = boardRecord
        ? boardRecord
        : [];


      boardResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const softwareVersionsList = await getSoftwareVersions();

      softwareVersionsList.sort(function (a: any, b: any) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      const cupHolderRecord = await getCupHoldersList();
      const cupHolderResults: any = cupHolderRecord
        ? cupHolderRecord
        : [];


      cupHolderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setBlenderDB(blenderResults)
      setBoardDB(boardResults)
      setSoftwareVersionsDB(softwareVersionsList)
      setLocationDB(locationIdResults);
      setDeployTypeDB(deploymentTypeResults);
      setManagerDB(deploymentManagerResults);
      setCupHolderDB(cupHolderResults);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocationChange = async (e: any) => {
    setLocationId(e.value);
    try {
      const locations: any = locationsData?.data ?? []
      const locationRecords = locations.filter(
        (location: any) => location?.id === e.value
      );

      const locationRecord: any = locationRecords[0];
      if (locationRecord !== null) {
        setLocationResult(locationRecord);
        setDeployTypeName(locationRecord?.deploymentType?.name);
        setManagerName(locationRecord?.deploymentManager?.firstName);
        setDeploymentTypeId(locationRecord?.deploymentTypeId);
        setDeployingPersonId(locationRecord?.deploymentManagerId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Machine
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create Machine</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            locationId: "",
            name: "",
            macAddress: "",
            serialNumber: "",
            deploymentDate: "",
            isActive: true,
            blenderId: "",
            boardId: "",
            cupHolderId : null,
            machineRemark: "",
            isPilot: false,
            trialEndDate: "",
            noteRelatedToMechanicalHardwareVersion: "",
            replaceNewTubeDoors1mmShorter: false,
            softwareVersion: "0",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            let hasErrors = false;
            if (!deploymentTypeId) {
              hasErrors = true;
              toast.error("Deployment type is required!");
            }
            // if (!deployingPersonId) {
            //   hasErrors = true;
            //   toast.error("Deploying person is required");
            // }
            if (hasErrors) {
              setLoading(false);
              return false;
            }


            const $inputData: any = {
              name: values.name,
              macAddress: values.macAddress,
              deploymentDate: Moment.utc(values.deploymentDate)
                .local()
                .format("YYYY-MM-DD"),
              deploymentTypeId: deploymentTypeId,
              deployingPersonId: deployingPersonId,
              serialNumber: values.serialNumber,
              remark: values.machineRemark,
              noteRelatedToMechanicalHardwareVersion: values.noteRelatedToMechanicalHardwareVersion,
              softwareVersion: values.softwareVersion,
              isActive: values.isActive,
              replaceNewTubeDoors1mmShorter: values.replaceNewTubeDoors1mmShorter,
              blenderId: values.blenderId,
              boardId: values.boardId,
              cupHolderId:values.cupHolderId !== null ? values.cupHolderId : null,
              isPilot: values.isPilot,
              trialEndDate: values.trialEndDate
            };

            if (values.trialEndDate) {
              $inputData['trialEndDate'] = Moment.utc(values.trialEndDate)
                .local()
                .format("YYYY-MM-DD")
            }

            if (locationId !== "") {
              $inputData["locationId"] =
                locationId;
            }

            const result = await createMachine($inputData);
            if (result?.data?.data?.createMachine) {
              toast.success("Machine created successfully");
              resetLocation?.current?.setValue("");
              resetManager.current.setValue("");
              resetBlender.current.setValue("");
              resetSoftwareversion.current.setValue("");
              resetBoard.current.setValue("");
              resetDeployType?.current?.setValue("");
              resetCupHolder.current.setValue("");
              setLocationResult("");
              setDeployTypeName("");
              setManagerName("");
              setLoading(false);
              resetDeploymentDate.current.state.inputValue = "";
              resetForm();
              navigate("/machines/list");
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="assignLocations form-label fs-4  fw-bold">
                      Assign Locations
                    </label>
                    <i className="assignLocations fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".assignLocations" className="tooltip-dashboard">
                      Choose a location where the machine is placed
                    </Tooltip>

                    <Select
                      ref={resetLocation}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="locationId"
                      defaultValue={{ label: locationResult?.name, value: locationId }}
                      placeholder=""
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        handleLocationChange(e);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="serialNumber form-label fs-4 required fw-bold">
                      Serial Number
                    </label>  <i className="serialNumber fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".serialNumber" className="tooltip-dashboard">
                      Enter the machine's serial number, located inside the service door
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.serialNumber) && touched.serialNumber,
                      })}
                      name="serialNumber"
                      id="serialNumber"
                      value={values.serialNumber}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="serialNumber" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="macAddress form-label fs-4 fw-bold">
                      Mac Address
                    </label>  <i className=" macAddress fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".macAddress" className="tooltip-dashboard">
                      The Mac address can be found on the bottom part of the service menu
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.macAddress) && touched.macAddress,
                      })}
                      name="macAddress"
                      id="macAddress"
                      value={values.macAddress}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="macAddress" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="machineName form-label fs-4  fw-bold">
                      Machine Name
                    </label>  <i className="machineName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".machineName" className="tooltip-dashboard">
                      the machine name should be comprised as the location name-citiy all in one word lower case letters
                    </Tooltip>
                    <input
                      className={clsx("form-control name", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="deploymentDate form-label fs-4 required fw-bold">
                      Deployment Date
                    </label>  <i className="deploymentDate fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".deploymentDate" className="tooltip-dashboard">
                      What capacity is the machine performing right now.
                    </Tooltip>
                    <Datetime
                      ref={resetDeploymentDate}
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      timeFormat={false}
                      className={clsx("date-control", {
                        "is-invalid":
                          Boolean(errors.deploymentDate) &&
                          touched.deploymentDate,
                      })}
                      value={values.deploymentDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("deploymentDate", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="deploymentDate" />
                    </div>
                  </div>

                  {locationId ? <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label required fs-4 fw-bold">
                      Deployment Type
                    </label>
                    <Select
                      ref={resetDeployType}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="deploymentTypeId"
                      defaultValue={{
                        label: _.capitalize(_.lowerCase('New Ready for deployment')),
                        value: 'ff74a26f-7a71-4466-8cc3-96f09a1ba46b', // Default its new ready for deployment
                      }}
                      placeholder={_.capitalize(_.lowerCase(deployTypeName))}
                      options={Array.from(deployTypeDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                        };
                      })}
                      onChange={(e: any) => {
                        setDeploymentTypeId(e.value);
                      }}
                    />
                  </div> : ''}

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="personDeploying form-label fs-4 fw-bold">
                      Person Deploying
                    </label>  <i className="personDeploying fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".personDeploying" className="tooltip-dashboard">
                      The Smoodi team member responsible for deploying the machine, or overseeing its deployment if conducted by a third party
                    </Tooltip>
                    <Select
                      ref={resetManager}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="deployingPersonId"
                      placeholder={managerName}
                      options={Array.from(managerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      })}
                      onChange={(e: any) => {
                        setDeployingPersonId(e.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="remark form-label fs-4 fw-bold">Remark </label>
                    <i className="remark fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".remark" className="tooltip-dashboard">
                      Add a note pertaining to this machine.
                    </Tooltip>
                    <textarea
                      name="machineRemark"
                      id="machineRemark"
                      className="form-control"
                      value={values.machineRemark}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="blender form-label required fs-4 fw-bold">
                      Blender
                    </label>  <i className="blender fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".blender" className="tooltip-dashboard">
                      The version of the blender assembly. Can be indentifiesd if blade guard is present  (version N and above) or written on the assembly (rev Q)
                    </Tooltip>
                    <Select
                      ref={resetBlender}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="blenderId"
                      placeholder=""
                      options={Array.from(blenderDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("blenderId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="blenderId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="board form-label required fs-4 fw-bold">
                      Board
                    </label>  <i className="board fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".board" className="tooltip-dashboard">
                      Version of the board in the back of the machine, if there is no need for ribbon cable, the version of the board is C
                    </Tooltip>
                    <Select
                      ref={resetBoard}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="boardId"
                      placeholder=""
                      options={Array.from(boardDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("boardId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="boardId" />
                    </div>
                  </div>
                  {/* <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="form-label fs-4 required fw-bold ps-1">
                      Connection Status
                     </label>  <i className="assignLocations fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".assignLocations" className="tooltip-dashboard">
                    Choose a location where the machine is placed
                    </Tooltip>
                    {Array.from(connectionDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="connectionStatus"
                            className="form-check-input"
                            type="radio"
                            name="connectionStatus"
                            value={value.id}
                            checked={values.connectionStatus === value.id}
                            onChange={() =>
                              setFieldValue("connectionStatus", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                         </label>  <i className="assignLocations fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".assignLocations" className="tooltip-dashboard">
                    Choose a location where the machine is placed
                    </Tooltip>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="connectionStatus" />
                    </div>
                  </div> */}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="softwareVersion form-label fs-4 fw-bold">
                      Software Version
                    </label>  <i className="softwareVersion fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".softwareVersion" className="tooltip-dashboard">
                      Enter the software version number of the machine, as displayed at the bottom of the service portal menu
                    </Tooltip>

                    <Select
                      ref={resetSoftwareversion}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="softwareVersion"
                      placeholder=""
                      options={Array.from(softwareVersionsDB).map((value: any) => {
                        return {
                          value: value,
                          label: value,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("softwareVersion", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="softwareVersion" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label  fs-4 fw-bold">
                      Cup Holder
                    </label>
                    <Select
                      ref={resetCupHolder}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="cupHolderId"
                      placeholder=""
                      options={Array.from(cupHolderDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("cupHolderId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="cupHolderId" />
                    </div>
                  </div>


                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-3">
                    <label className="form-label fs-4 fw-bold d-block">
                      Replace new tube doors -1mm shorter
                    </label>
                    <div className="form-check form-switch form-check-custom form-check-solid w-100">
                      <input
                        className="form-check-input"
                        type="checkbox"

                        defaultChecked={values.replaceNewTubeDoors1mmShorter === true}
                        onChange={(event) => {
                          setFieldValue("replaceNewTubeDoors1mmShorter", event.target.checked)
                        }

                        }
                        name="replaceNewTubeDoors1mmShorter"
                      />
                    </div>


                  </div>

                </div>


                <div className="row">

                  {locationId !== '' ? <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-3">
                    <label className="form-label fs-4 fw-bold d-block">
                      Is Pilot?
                    </label>
                    <div className="form-check form-switch form-check-custom form-check-solid w-100">
                      <input
                        className="form-check-input"
                        type="checkbox"

                        defaultChecked={values.isPilot === true}
                        onChange={(event) => {
                          setFieldValue("isPilot", event.target.checked)
                          setFieldValue("trialEndDate", "")
                        }
                        }
                        name="isPilot"
                      />
                    </div>


                  </div> : ''}

                  {values.isPilot ?
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Trial End Date
                      </label>
                      <Datetime
                        ref={resetTrialEndDate}
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect
                        timeFormat={false}
                        className={clsx("date-control", {
                          "is-invalid":
                            Boolean(errors.trialEndDate) &&
                            touched.trialEndDate,
                        })}
                        value={values.trialEndDate}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("trialEndDate", dt);
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="trialEndDate" />
                      </div>
                    </div> : ''}


                  <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 w-xl-25 mb-3 mb-lg-4">
                    <label className="mechanicalHardwareVersion form-label fs-4 fw-bold">Note related to mechanical hardware version </label>
                    <i className="mechanicalHardwareVersion fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".mechanicalHardwareVersion" className="tooltip-dashboard">
                      Comment if the mechanical hardware is abnormal or has an unusual combination of parts.
                    </Tooltip>
                    <textarea
                      name="noteRelatedToMechanicalHardwareVersion"
                      id="noteRelatedToMechanicalHardwareVersion"
                      className="form-control"
                      value={values.noteRelatedToMechanicalHardwareVersion}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD MACHINE</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateMachine;
