import { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import clsx from "clsx";
import Moment from "moment";
import $ from "jquery";
import Select from "react-select";
import { Toaster } from "react-hot-toast";
import {
  getLocations,
  getMarketSegment,
  getRegion,
  getUsers,
  updateLocationStatus,
  getFilterProviders,
  getBusinessModelsList,
} from "../../models/_location";
import { getCustomers, getDistributor } from "../../models/_customer";
import { KTSVG } from "../../../_metronic/helpers";
import _ from "lodash";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DownloadExcel } from "../ExcelHelper";
import ReactSelect from "react-select";
import { EditLocation } from "./EditLocation";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import { UpdatePriceLocation } from "./UpdatePriceLocation";
import Columns from "../../../_metronic/helpers/Columns";
import { getModuleDataDisplaySettingByKey, updateModuleDataDisplaySettingByKey } from "../../models/_user";
import { Option } from '../../../_metronic/helpers/MySelect';
import { getLocationTypesList } from "../../models/_locationType";
import { FreezerInformation } from "./FreezerInformation";
import { Tooltip } from "react-tooltip";
import ReactivateLocationModal from "./ReActivateLocationModal";
import moment from "moment";
class LocationsList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    let searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const currentPage = searchParams.get('page');
    const fromDate = searchParams.get('from_date');
    const toDate = searchParams.get('to_date');
    const locationName = searchParams.get('name');
    const customerName = searchParams.get('customer_Id');
    const CustomerIdsArray = customerName ? customerName.split(',').map((x) => x) : "";

    const DeploymentManager = searchParams.get('deployment_manager_id');
    const DeploymentManagerIdArray = DeploymentManager ? DeploymentManager.split(',').map((x) => x) : "";

    const marketSegment = searchParams.get('market_segment_id');
    const marketSegmentIdArray = marketSegment ? marketSegment.split(',').map((x) => x) : "";

    const businessModel = searchParams.get('business_model_id');
    const businessModelArray = businessModel ? businessModel.split(',').map((x) => x) : "";

    const regionId = searchParams.get('region_id');
    const regionIdArray = regionId ? regionId.split(',').map((x) => x) : "";

    const locationType = searchParams.get('location_type_id');
    const locationTypeIdArray = locationType ? locationType.split(',').map((x) => x) : "";

    const pendingInstallation = searchParams.get('pending_installation_location');
    const provider = searchParams.get('filter_provider_id');
    const providerArray = provider ? provider.split(',').map((x) => x) : "";

    const status = searchParams.get('is_active');
    const distributerIdString = searchParams.get('distributor_id');
    const distributerIdsArray = distributerIdString ? distributerIdString.split(',').map((x) => x) : "";

    // query parameter from dashboard
    const query = new URLSearchParams(window.location.search);
    const installationQuery = query.get("pending_installation_location");
    let filterPendingInstallation: any = undefined;

    let installationSelected: any = [];
    if (installationQuery != null) {
      installationSelected.push({
        label: installationQuery === 'true' ? 'Yes' : 'No',
        value: Boolean(installationQuery),
      });
      filterPendingInstallation = Boolean(installationQuery);
    } else {
      filterPendingInstallation = undefined;
    }

    this.state = {
      resetStatus: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      resetPendingInstallation: createRef(),
      filterDistributor: distributerIdsArray || "",
      filterCustomer: CustomerIdsArray || "",
      filterManager: DeploymentManagerIdArray || "",
      filterToDate: toDate || "",
      filterFromDate: fromDate || "",
      filterName: locationName || "",
      filterProvider: providerArray || '',
      filterStatus: status || "true",
      filterMarketSegment: marketSegmentIdArray || "",
      filterBusinessModel: businessModelArray || "",
      filterRegionId: regionIdArray || "",
      filterType: locationTypeIdArray || "",
      locationEditModal: false,
      freezerInformationModal: false,
      locationUpdatePriceModal: false,
      currentLocationId: id || "",
      locationList: [],
      addLoading: false,
      filterLoading: false,
      loading: false,
      locationListObj: {},
      marketSegmentDB: [],
      businessModelDB: [],
      deployTypeDB: [],
      managerDB: [],
      customerDB: [],
      distributorDB: [],
      locationRegionDB: [],
      filterProviderDB: [],
      statusSelected: [],
      customerSelected: null,
      distributorSelected: null,
      locationRegionSelected: null,
      filterProviderSelected: null,
      marketSegmentSelected: null,
      businessModelSelected: null,
      deploymentManagerSelected: null,
      locationTypeSelected: null,
      downloadLoading: false,
      installationSelected: installationSelected,
      filterPendingInstallation: pendingInstallation || "",
      finalUrl: "",
      page: currentPage || 0,
      perPage: 50,
      statusDB: [
        {
          id: "true",
          name: "Active",
        },
        {
          id: "false",
          name: "Inactive",
        },
        {
          id: "all",
          name: "All"
        }
      ],
      isInstallationDB: [
        {
          id: "true",
          name: "Yes",
        },
        {
          id: "false",
          name: "No"
        }
      ],
      moduleKeyName: 'location_list',
      selectedColumns: [],
      columnData: [
        { id: 0, label: 'Location ID', key: 'index' },
        { id: 1, label: 'Customer Name', key: 'customerName' },
        { id: 2, label: 'Location Name', key: 'locationName' },
        { id: 3, label: 'Location Address', key: 'locationAddress' },
        { id: 4, label: 'Location Region', key: 'locationRegion' },
        { id: 5, label: 'Business Model', key: 'businessModel' },
        { id: 6, label: 'Market Segment', key: 'marketSegment' },
        { id: 7, label: 'Deployment Manager', key: 'deploymentManager' },
        { id: 8, label: 'Distributor', key: 'distributor' },
        { id: 9, label: 'Location Type', key: 'locationType' },
        { id: 10, label: 'Filter Provider', key: 'filterProvider' },
        { id: 11, label: 'Target Launch Date', key: 'targetLaunchDate' },
        { id: 12, label: 'No.Of Blenders', key: 'totalBlenders' },
        { id: 13, label: 'No.Of Freezers', key: 'totalFreezers' },
        { id: 14, label: 'Sampling Time', key: 'samplingRecommendedTime' },
        { id: 15, label: 'Sampling Days', key: 'samplingRecommendedDay' },
        { id: 16, label: 'Water Pressure', key: 'waterPressure' },
        { id: 17, label: 'CreatedAt', key: 'createdAt' },
        { id: 18, label: 'Status', key: 'isActive' },
        { id: 19, label: 'Action', key: 'action' },
      ]
    };
  }

  async componentWillMount() { }

  async componentDidUpdate() {
    await this.handleLocationFilterSet()
   }

  async componentDidMount() {
    var _ = this;
    $("body").on("change", ".togBtn", function () {
      let locationStatus = false
      let confirmedToActivateMachines = false
      const record = $(this).attr("data-recordId");
      const status = $(this).attr("data-status");
      if(status == 'false') {
        _.setState({
          changeReactivateModal: true,
          currentLocationId: record
        })
      } else {
        _.locationStatus(record, locationStatus,confirmedToActivateMachines);
        _.setState({
          currentLocationId: record,
        })
      }
      return false;
    });

    $("body").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        locationEditModal: true,
        currentLocationId: record
      })
    });
    $("body").on("click", ".freezerInformation", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        freezerInformationModal: true,
        currentLocationId: record
      })
    });
    $("body").on("click", ".updatePrice", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        locationUpdatePriceModal: true,
        currentLocationId: record
      })
    });
    $("body").on("click", ".reActivateModal", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        changeReactivateModal: true,
        currentLocationId: record
      })
    });
    await this.getFilterRecords();
    this.renderDataTable([]);
  }

  getFilter = () => {
    let filter: any = {};
    if (this.state.filterCustomer.length > 0) {
      filter["customer_id"] = { in: this.state.filterCustomer };
    }
    if (this.state.filterManager.length > 0) {
      filter["deployment_manager_id"] = { in: this.state.filterManager };
    }
    if (this.state.filterMarketSegment.length > 0) {
      filter["market_segment_id"] = { in: this.state.filterMarketSegment };
    }

    if (this.state.filterBusinessModel.length > 0) {
      filter["business_model_id"] = { in: this.state.filterBusinessModel };
    }

    if (this.state.filterProvider.length > 0) {
      filter["filter_provider_id"] = { in: this.state.filterProvider };
    }
    if (this.state.filterRegionId.length > 0) {
      filter["region_id"] = { in: this.state.filterRegionId };
    }
    if (this.state.filterType.length > 0) {
      filter["location_type_id"] = { in: this.state.filterType };
    }
    if (this.state.filterDistributor.length > 0) {
      filter["distributor_id"] = { in: this.state.filterDistributor };
    }
    if (this.state.filterName) {
      filter["name"] = { like: "%" + this.state.filterName + "%" };
    }
    if (this.state.filterStatus === "true") {
      filter["is_active"] = { eq: true };
    } else if (this.state.filterStatus === "false") {
      filter["is_active"] = { eq: false };
    }
    if (this.state.filterStatus === "all") {
      delete filter["is_active"]
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(this.state.filterFromDate);
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    return filter
  }
  getURL = (baseURL: string) => {
    const isApplyDistributerFilter = this.state.filterDistributor;
    const isApplyFromDateFilter = this.state.filterFromDate;
    const isApplyToDateFilter = this.state.filterToDate;
    const isApplyCustomerNameFilter = this.state.filterCustomer;
    const isApplyStatusFilter = this.state.filterStatus;
    const isApplyFilterManager = this.state.filterManager;
    const isApplyProviderFilter = this.state.filterProvider;
    const isApplyMarketSegmentFilter = this.state.filterMarketSegment;
    const isApplyBusinessModelFilter = this.state.filterBusinessModel;
    const isApplyRegionIdFilter = this.state.filterRegionId;
    const isApplyLocationTypeFilter = this.state.filterType;
    const isApplyLocationNameFilter = this.state.filterName;
    const isApplyPendingInstallationFilter = this.state.filterPendingInstallation;

    if (isApplyFromDateFilter) {
      let date = moment(isApplyFromDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&from_date=${encodedDate}`
    }
    if (isApplyToDateFilter) {
      let date = moment(isApplyToDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&to_date=${encodedDate}`
    }
    if (isApplyDistributerFilter && isApplyDistributerFilter.length > 0) {
      const distributerIds = isApplyDistributerFilter.join(',');
      baseURL += `&distributor_id=${distributerIds}`
    }
    if (isApplyCustomerNameFilter) {
      const customerIds = isApplyCustomerNameFilter.join(',');
      baseURL += `&customer_Id=${customerIds}`
    }
    if (isApplyFilterManager) {
      const managerIds = isApplyFilterManager.join(',');
      baseURL += `&deployment_manager_id=${managerIds}`
    }
    if (isApplyProviderFilter) {
      const providerIds = isApplyProviderFilter.join(',');
      baseURL += `&filter_provider_id=${providerIds}`
    }
    if (isApplyMarketSegmentFilter) {
      const marketSegmentIds = isApplyMarketSegmentFilter.join(',');
      baseURL += `&market_segment_id=${marketSegmentIds}`
    }
    if (isApplyBusinessModelFilter) {
      const businessModelIds = isApplyBusinessModelFilter.join(',');
      baseURL += `&business_model_id=${businessModelIds}`
    }
    if (isApplyRegionIdFilter) {
      const regionIds = isApplyRegionIdFilter.join(',');
      baseURL += `&region_id=${regionIds}`
    }
    if (isApplyLocationTypeFilter) {
      const locationTypeIds = isApplyLocationTypeFilter.join(',');
      baseURL += `&location_type_id=${locationTypeIds}`
    }
    if (isApplyStatusFilter === "true" || isApplyStatusFilter === "false" || isApplyStatusFilter === "all") {
      baseURL += `&is_active=${(isApplyStatusFilter)}`;
    }
    if (isApplyPendingInstallationFilter) {
      baseURL += `&pending_installation_location=${(isApplyPendingInstallationFilter)}`;
    }
    
    if (isApplyLocationNameFilter) {
      let decodedSerialNumber = decodeURIComponent(isApplyLocationNameFilter); 
      let encodedSerialNumber = encodeURIComponent(decodedSerialNumber); 
      baseURL += `&name=${encodedSerialNumber}`; 
    }
    return baseURL;
  }
  // rendering datatable jquery
  renderDataTable = async (rows: any) => {
    var _this = this;
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    const filter = this.getFilter();
    await this.getColumnData();
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    let extraFilter: any = {};
    if (this.state.filterPendingInstallation === "true") {
      extraFilter["pending_installation_location"] = true;
    } else if (this.state.filterPendingInstallation === "false") {
      extraFilter["pending_installation_location"] = false;
    }
    $(document).ready(function () {
      $("#locationTable").DataTable().destroy();

      // eslint-disable-next-line
      let table = $("#locationTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Bearer " + authToken);
          },
          "data": function (d: any) {
            var gql = ` query getLocations(
            $where: LocationsResolver_GetLocations_FilterInputType
            $paginate: PaginatorArgs
            $extraFilter: LocationExtraFilterInputs
          ){
            getLocations(where: $where, paginate: $paginate, extraFilter:$extraFilter) {
              data {
                id
                uuid
                customerId
                name
                address
                regionId
                contactName
                contactPhone
                contactEmail
                marketSegmentId
                deploymentManagerId
                gasSupplierId
                locationTypeId
                targetLaunchDate
                noOfBlenders
                noOfFreezers
                distributorId
                samplingRecommendedTime
                samplingRecommendedDays
                createdById
                createdAt
                updatedById
                updatedAt
                isActive
                state
                city
                zipCode
                latitude
                longitude
                machineCount
                customerSecondContact
                secondContactName
                secondContactPhoneNumber
                secondContactEmail
                secondContactNameRole
                customerItContact
                itContactName
                itContactPhoneNumber
                itContactEmail
                itContactNameRole
                customerServiceContact
                serviceContactName
                serviceContactPhoneNumber
                serviceContactEmail
                serviceContactNameRole
                typeOfContactPhoneNumber
                typeOfSecondContactPhoneNumber
                typeOfItContactPhoneNumber
                typeOfServiceContactPhoneNumber
                wifiCredentials {
                  name
                  password
                }
                waterPressure
                storeManagerContact
                storeManagerContactName
                storeManagerContactPhoneNumber
                storeManagerContactEmail
                typeOfStoreManagerContactPhoneNumber
                filterProviderId
                gasSupplier{
                  id
                  name
                }
                createdBy {
                  id
                  firstName
                }
                updatedBy {
                  id
                  firstName
                }
                marketSegment {
                  id
                  name
                }
                deploymentManager {
                  id
                  firstName
                }
                locationType {
                  id
                  name
                }
                region {
                  id
                  name
                }
                distributor {
                  id
                  name
                  createdAt
                  updatedAt
                }
                filterProvider {
                  id
                  title
                }
                flavorPrices {
                  flavor
                  price
                }
                businessModel {
                  id
                  name
                }
                customer {
                  id
                  name
                  customerCompanyName
                  customerContactName
                  customerContactPhoneNumber
                  customerContactEmail
                  customerContactNameRole
                  accountType
                  distributorId
                  customerSecondContact
                  customerSecondContactName
                  customerSecondContactPhoneNumber
                  customerSecondContactEmail
                  customerSecondContactNameRole
                  isActive
                  isVip
                }
              }
              totalPages
              total
              perPage
              page
            }
          }`;

            const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            var query = {
              "operationName": null,
              "query": gql,
              "variables": {
                "where": filter,
                extraFilter: extraFilter,
                paginate: {
                  "page": currentPage,
                  "per_page": length
                }
              }
            };

            return JSON.stringify(query);
          },
          "dataSrc": function (json) {

            json.recordsTotal = json.data.getLocations.total;
            json.recordsFiltered = json.data.getLocations.total;

            const rows: any[] = [];
            const records = json.data.getLocations.data;
            for (const record of records) {

              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let updatedAt = "";
              if (record.updatedAt) {
                updatedAt = Moment.utc(record.updatedAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              const tempRecord = {
                id: record.uuid,
                customerName: record.customer?.name ?? "",
                locationName: record.name,
                locationAddress: record.address,
                locationRegion: record.region?.name ?? "",
                businessModel: record.businessModel?.name ?? "",
                marketSegment: record.marketSegment?.name ?? "",
                deploymentManager: record.deploymentManager?.firstName ?? "",
                locationType: _.capitalize(
                  _.lowerCase(record.locationType?.name ?? "")
                ),
                filterProvider: _.capitalize(
                  _.lowerCase(record.filterProvider?.title ?? "")
                ),
                credentials: record.wifiCredentials,
                distributor: record.distributor?.name ?? "",
                targetLaunchDate: record?.targetLaunchDate ? Moment.utc(record.targetLaunchDate)
                  .local()
                  .format("YYYY-MM-DD") : "",
                totalBlenders: record.noOfBlenders,
                totalFreezers: record.noOfFreezers,
                samplingRecommendedTime: record.samplingRecommendedTime,
                samplingRecommendedDay: record.samplingRecommendedDays,
                contactName: record.contactName,
                contactPhone: record.contactPhone,
                contactEmail: record.contactEmail,
                typeOfContactPhoneNumber: _.capitalize(
                  _.lowerCase(record.typeOfContactPhoneNumber)
                ),
                createdAt,
                isActive: record.isActive,
                customerSecondContact: record.customerSecondContact,
                customerSecondContactName: record.secondContactName,
                customerSecondContactPhoneNumber: record.secondContactPhoneNumber,
                secondContactPhoneType: _.capitalize(
                  _.lowerCase(record.typeOfSecondContactPhoneNumber)
                ),
                customerSecondContactEmail: record.secondContactEmail,
                customerSecondContactNameRole: record.secondContactNameRole,
                customerItContact: record.customerItContact,
                itContactName: record.itContactName,
                itContactPhoneNumber: record.itContactPhoneNumber,
                itContactPhoneType: _.capitalize(
                  _.lowerCase(record.typeOfItContactPhoneNumber)
                ),
                itContactEmail: record.itContactEmail,
                itContactNameRole: record.itContactNameRole,
                customerServiceContact: record.customerServiceContact,
                serviceContactName: record.serviceContactName,
                serviceContactPhoneNumber: record.serviceContactPhoneNumber,
                serviceContactPhoneType: _.capitalize(
                  _.lowerCase(record.typeOfServiceContactPhoneNumber)
                ),
                serviceContactEmail: record.serviceContactEmail,
                serviceContactNameRole: record.serviceContactNameRole,

                storeManagerContact: record.storeManagerContact,
                storeManagerContactName: record.storeManagerContactName,
                storeManagerContactEmail: record.storeManagerContactEmail,
                storeManagerContactPhoneNumber: record.storeManagerContactPhoneNumber,
                typeOfStoreManagerContactPhoneNumber:
                  _.capitalize(
                    _.lowerCase(record.typeOfStoreManagerContactPhoneNumber)),

                updatedAt,
                recordId: record.id,
                customerId: record.customerId,
                distributorId: record.distributorId,
                record: record,
                isVip: record.customer?.isVip,
                waterPressure: record.waterPressure,
              };

              const subRecords = record.subTasks ?? "";
              for (const subRecord of subRecords) {
                subRecord["record"] = { ...subRecord };
              }
              rows.push(tempRecord);
            }
            if (rows && rows.length !== 0) {
              _this.setState({
                locationList: rows
              })
            }
            return rows;
          }
        },
        "initComplete": function (settings, json) {
          table.page(Number(_this.state.page)).draw(false);

        },
        // data: rows,
        columns: [
          {
            name: "index",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let json = JSON.stringify(row);
              let jsonString = json.replaceAll("'","")
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view location"><a class="clickable_entry" data-value=\'' + jsonString + '\' href="/locations/list/history/' +
                row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"> ' +
                row.id +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "customerName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnData = ""
              if (row.isVip === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view customer"><a href="/customers/list/history/' +

                  row.customerId + '?page=' + _this.state.page + _this.state.finalUrl +
                  '"> <i class="fa fa-crown text-warning me-1"></i>' +
                  row.customerName +
                  '</a></div>'
              } else {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view customer"><a href="/customers/list/history/' +
                  row.customerId + '?page=' + _this.state.page + _this.state.finalUrl +
                  '"> ' +
                  row.customerName +
                  "</div></a>";
              }
              return returnData;
            },
          },
          {
            name: "locationName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('locationName'),
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view location"><a href="/locations/list/history/' +
                row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"> ' +
                row.locationName +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "locationAddress",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('locationAddress'),
            render: function (data, type, row) {
              return row.locationAddress;
            },
          },
          {
            name: "locationRegion",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('locationRegion'),
            render: function (data, type, row) {
              return row.locationRegion;
            },
          },
          {
            name: "businessModel",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('businessModel'),
            render: function (data, type, row) {
              return row.businessModel;
            },
          },
          {
            name: "marketSegment",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('marketSegment'),
            render: function (data, type, row) {
              return row.marketSegment;
            },
          },
          {
            name: "deploymentManager",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('deploymentManager'),
            render: function (data, type, row) {
              return row.deploymentManager;
            },
          },
          {
            name: "distributor",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('distributor'),
            render: function (data, type, row) {
              return row.distributor;
            },
          },
          {
            name: "locationType",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('locationType'),
            render: function (data, type, row) {
              return row.locationType;
            },
          },
          {
            name: "filterProvider",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('filterProvider'),
            render: function (data, type, row) {
              return row.filterProvider;
            },
          },
          {
            name: "targetLaunchDate",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('targetLaunchDate'),
            render: function (data, type, row) {
              return row.targetLaunchDate;
            },
          },
          {
            name: "totalBlenders",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('totalBlenders'),
            render: function (data, type, row) {
              return row.totalBlenders;
            },
          },
          {
            name: "totalFreezers",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('totalFreezers'),
            render: function (data, type, row) {
              return row.totalFreezers;
            },
          },
          {
            name: "samplingRecommendedTime",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('samplingRecommendedTime'),
            render: function (data, type, row) {
              return row.samplingRecommendedTime;
            },
          },
          {
            name: "samplingRecommendedDay",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('samplingRecommendedDay'),
            render: function (data, type, row) {
              return row.samplingRecommendedDay;
            },
          },
          {
            name: "waterPressure",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('waterPressure'),
            render: function (data, type, row) {
              return row.waterPressure;
            },
          },
          {
            name: "createdAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('createdAt'),
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "isActive",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('isActive'),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-location") === true) {
                let isChecked =
                  row.isActive === true
                    ? 'checked="checked"'
                    : row.isActive === false;
                returnData +=
                `
                <label class="status-switch ">
                  <input type="checkbox" ${isChecked} data-recordId="${row.recordId}" data-status="${row.isActive}" class="togBtn ">
                  <div class="status-slider round ${row.isActive === false ? 'reActivateModal' : ''}"></div>
                </label>`;
              } else {
                if (row.isActive === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }

              return returnData;
            },
          },
          {
            name: "action",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('action'),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-location") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit location"><button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";

                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to update location price"><button class="btn btn-success btn updatePrice p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-dollar' style='font-size:1rem;'></i></button></div>";

                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to Freezer Information"><button class="btn btn-success btn freezerInformation p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-info-circle' style='font-size:1rem;'></i></button></div>";
              }
              returnData +=
                '<div data-bs-toggle="tooltip" title="Click here to view location"><a class="btn btn-primary btn p-2 px-4 m-1" href="/locations/list/history/' +
                row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"><i class="fa fa-eye"></i></a></div>';
              return returnData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          var title: any = [];
          $("#locationTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      table.on('page', function () {
        const currentPage = table.page.info();

        _this.setState({
          page: currentPage.page
        })

      });
      $(document)[0].oncontextmenu = function () { return false; }
      //     var $contextMenu = $("#contextMenu");

      //   $("body").mousedown( function(e) {
      //     console.log('eh')
      //        $contextMenu.css({
      //             display: "block",
      //             left: e.clientX,

      //             top: e.clientY
      //        });
      //       debugger;
      //        return false;
      //   });

      //   $('#contextMenu').click(function() {
      //        $contextMenu.hide();
      //   });

      // $("#contextMenu li a").click(function(e){
      //   var  f = $(this);
      //   debugger;
      // });


      $('#locationTable tbody').mousedown(function (e) {
        if (e.buttons === 1) return true;
        e.preventDefault();
        var top = e.clientY;
        var left = e.clientX;
        const recordString = $(e.target).parents('tr').find('a.clickable_entry').attr('data-value');
        let record;
        if (recordString) {
          record = JSON.parse(recordString)
        }
        if (record) {
          $('#view_record_link').attr('href', `/locations/list/history/${record.recordId}?page=${_this.state.page}${_this.state.finalUrl}`)
          $('#edit_record_link').attr('data-recordId', record.recordId)
          $('#update_flavor_price').attr('data-recordId', record.recordId)
          $('#freezer_information').attr('data-recordId', record.recordId)
          $("#context-menu").css({
            top: top,
            left: left
          }).show();
        }

        return false;

      })
      $('html').click(function () {
        $("#context-menu").hide();
      })
      $(".toggle-vis").on('change', function (e) {
        e.preventDefault();
        const column = table.column($(this).attr('data-column'));
        const checked = $(this).is(":checked")

        $('.columnBtn').click(function (event) {
          if (checked) {
            column.visible(true);
          }
          else {
            column.visible(false);
          }
        });
      });
      _this.displaySelectedColumns(table);
      $(".dt-buttons").remove();
    });
  };

  async getFilterRecords() {
    const customerRecord = await getCustomers();
    const customerResults: any = customerRecord.data ? customerRecord.data : [];
    customerResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const distributorRecord = await getDistributor();
    const distributorIdResults: any = distributorRecord
      ? distributorRecord
      : [];
    distributorIdResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    distributorIdResults.push({
      id: "other",
      name: "Other",
    });
    const marketSegmentRecord = await getMarketSegment();
    const marketSegmentResults: any = marketSegmentRecord
      ? marketSegmentRecord
      : [];
    marketSegmentResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const locationTypeRecord = await getLocationTypesList();
    const locationTypeResults: any = locationTypeRecord
      ? locationTypeRecord
      : [];
    locationTypeResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const deploymentManagerRecord = await getUsers();
    const deploymentManagerResults: any = deploymentManagerRecord
      ? deploymentManagerRecord
      : [];
    deploymentManagerResults.sort(function (a: any, b: any) {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const locationRegionRecord = await getRegion();
    const locationRegionResults: any = locationRegionRecord
      ? locationRegionRecord
      : [];
    locationRegionResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const filterProviderRecord = await getFilterProviders();
    const filterProviderResults: any = filterProviderRecord
      ? filterProviderRecord
      : [];

    filterProviderResults.sort(function (a: any, b: any) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const filterBusinessModel = await getBusinessModelsList();
    const filterBusinessModelsResults: any = filterBusinessModel
      ? filterBusinessModel
      : [];

    filterBusinessModelsResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.setState({
      marketSegmentDB: marketSegmentResults,
      businessModelDB: filterBusinessModelsResults,
      deployTypeDB: locationTypeResults,
      managerDB: deploymentManagerResults,
      locationRegionDB: locationRegionResults,
      filterProviderDB: filterProviderResults,
      distributorDB: distributorIdResults,
      customerDB: customerResults,
    });
  }

  handleDownloadExcel = async () => {
    const filter = this.getFilter();
    let extraFilter: any = {};
    if (this.state.filterPendingInstallation === "true") {
      extraFilter["pending_installation_location"] = true;
    } else if (this.state.filterPendingInstallation === "false") {
      extraFilter["pending_installation_location"] = false;
    }
    const result: any = await getLocations(filter, undefined, extraFilter);
    const excelRecords = [];
    for (const record of result.data) {
      let ttPrice = 0;
      let bbPrice = 0;
      let ggPrice = 0;
      let ppPrice = 0;
  let sbPrice = 0;
  let mmPrice = 0;
      const flavorPrices = record?.flavorPrices ?? [];
      flavorPrices.forEach((element: any) => {
        switch (element.flavor) {
          case "TT":
            ttPrice = element.price;
            break;
          case "GG":
            ggPrice = element.price;
            break;
          case "BB":
            bbPrice = element.price;
            break;
            case "PP":
        ppPrice = element.price;
        break;
      case "SB":
        sbPrice = element.price;
        break;
      case "MM":
        mmPrice = element.price;
        break;
        }
      });
      const tempRecord = {
        id: record.uuid,
        'Customer mame': record.customer?.name ?? "",
        'name': record.name,
        'Address': record.address,
        'Business model': record?.businessModel?.name ?? "",
        "City": record.city,
        "State": record.state,
        'Zip code': record.zipCode,
        'Region': record.region?.name ?? "",
        'Market segment': record.marketSegment?.name ?? "",
        'Deployment manager': record.deploymentManager?.firstName ?? "",
        'Location type': _.capitalize(
          _.lowerCase(record.locationType?.name ?? "")
        ),
        'Filter provider': _.capitalize(
          _.lowerCase(record.filterProvider?.title ?? "")
        ),
        'Distributor': record.distributor?.name ?? "",
        'Target launch date': Moment.utc(record.targetLaunchDate)
          .local()
          .format("YYYY-MM-DD"),
        'Total blenders': record.noOfBlenders,
        'Total freezers': record.noOfFreezers,
        'Sampling recommended time': record.samplingRecommendedTime,
        'Sampling recommended day': record.samplingRecommendedDays,
        'Contact name': record.contactName ?? "",
        'Contact phone': record.contactPhone ?? "",
        'Contact email': record.contactEmail ?? "",
        'Type of contact phone number': _.capitalize(
          _.lowerCase(record.typeOfContactPhoneNumber)
        ),
        'Created at': Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        'Water Pressure': record.waterPressure ?? "",
        'Is active?': record.isActive === true ? "True" : "False",
        'Second contact name': record.secondContactName,
        'Second contact phone number': record.secondContactPhoneNumber,
        'Second contact phone type': _.capitalize(
          _.lowerCase(record.typeOfSecondContactPhoneNumber)
        ),
        'Second contact email': record.secondContactEmail,
        'Second contact role': record.secondContactNameRole,
        'IT contact name': record.itContactName,
        'IT contact phone number': record.itContactPhoneNumber,
        'IT contact phone type': _.capitalize(
          _.lowerCase(record.typeOfItContactPhoneNumber)
        ),
        'IT contact email': record.itContactEmail,
        'IT contact role': record.itContactNameRole,
        'Service contact name': record.serviceContactName,
        'Service contact phone number': record.serviceContactPhoneNumber,
        'Service contact phone type': _.capitalize(
          _.lowerCase(record.typeOfServiceContactPhoneNumber)
        ),
        'Service contact email': record.serviceContactEmail,
        'Service contact role': record.serviceContactNameRole,
        'Store manager name': record.storeManagerContactName,
        'Store manager email': record.storeManagerContactEmail,
        'Store manager phone number': record.storeManagerContactPhoneNumber,
        'Store manager phone type':
          _.capitalize(
            _.lowerCase(record.typeOfStoreManagerContactPhoneNumber)),

        "Tropical Vibes Price": ttPrice,
        "Green Energizer Price": ggPrice,
        "Brain Boost Price": bbPrice,
        "Magical Mocha Price": mmPrice,
        "Peanut Protein Price": ppPrice,
        "Strawberry Banana Price": sbPrice,
        'Updated at': Moment.utc(record.updatedAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      excelRecords.push(tempRecord);
    }

    DownloadExcel(excelRecords, "SHEET_NAME", "Locations_list");
  };

  handleCloseReactivateLocation = () => {
    this.setState({
      changeReactivateModal: false,
      currentLocationId: ""
    })
  }

  locationStatus = async (recordId: any, locationStatus: boolean, confirmedToActivateMachines:boolean) => {
    try {
      await updateLocationStatus(recordId, locationStatus, confirmedToActivateMachines);
      this.refreshTable();
    } catch (e: any) {
      console.log(e);
    }
  };
  getLabelForStatus(status: any) {
    if (status === "true") {
      return "Active";
    } else if (status === "false") {
      return "Inactive";
    } else if (status === "all") {
      return "All";
    } else {
      return "Active";
    }
  }
  refreshTable = async () => {
    //await this.getFilterRecords();
    this.renderDataTable([]);
  }
  handleLocationClose = () => {
    this.setState({
      locationEditModal: false,
      currentLocationId: ""
    })
  }

  handleFreezerClose = () => {
    this.setState({
      freezerInformationModal: false,
      currentLocationId: ""
    })
  }

  handleLocationFlavorPriceClose = () => {
    this.setState({
      locationUpdatePriceModal: false,
      currentLocationId: ""
    })
  }

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const url = this.getURL(`/locations/list?page=${this.state.page}`);
    window.history.replaceState({}, '', url);
    this.setState({
      finalUrl: this.getURL('')
    });
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    this.state.resetStatus.current.setValue("");

    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.state.resetPendingInstallation.current.setValue("");
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterStatus: "",
      filterName: "",
      filterCustomer: "",
      filterDistributor: "",
      filterType: "",
      filterManager: "",
      filterMarketSegment: "",
      filterBusinessModel: "",
      filterRegionId: "",
      filterProvider: "",
      customerSelected: null,
      distributorSelected: null,
      filterProviderSelected: null,
      locationRegionSelected: null,
      marketSegmentSelected: null,
      businessModelSelected: null,
      deploymentManagerSelected: null,
      locationTypeSelected: null,
      filterPendingInstallation: "",
    });
    window.history.replaceState({}, '', "/locations/list");
    await this.getFilterRecords();
    this.renderDataTable([]);

    this.setState({
      filterLoading: false,
    });
    return false;
  };
  // async getColumnData() {
  getColumnData = async () => {
    var _ = this;
    const data = await getModuleDataDisplaySettingByKey(this.state.moduleKeyName);
    if (data) {
      _.setState({ selectedColumns: data.metaValue })
      return data.metaValue;
    }
    return [];
  }

  displaySelectedColumns = async (table: any) => {
    var _this = this;
    if (_this.state.selectedColumns.length > 0) {
      table.settings().init().columns?.map(function (val: any, key: any) {
        if (!_this.state.selectedColumns.includes(val.name)) {
          table.column(key).visible(false);
        }
        return false;
      });
    }
    $('#locationTable').show();
    return false;
  };

  handleColumnDataSelect = async (e: any) => {
    const selCol = (e.target.checked)
      ? [...this.state.selectedColumns, e.target.value]
      : this.state.selectedColumns.filter((item: string) => item !== e.target.value);
    this.setState({ selectedColumns: selCol });
  };

  handleColumnDataSubmit = async (e: any) => {
    const selectedColumns = this.state.selectedColumns;
    await updateModuleDataDisplaySettingByKey(this.state.moduleKeyName, selectedColumns);
    this.refreshTable();
  };
  handleLocationFilterSet = async () => {
    if (this.getURL(''))
    {
      if (this.state.filterDistributor) {
        const selectDistributor = this.state.distributorDB.filter((data: any) => this.state.filterDistributor.includes(data.id))
        const finalSelectedDistributorResponse = selectDistributor.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedDistributorResponse) !== JSON.stringify(this.state.distributorSelected)) {
          this.setState({
            distributorSelected: finalSelectedDistributorResponse,
          });
        }
      }
      if (this.state.filterCustomer) {
        const selectCustomer = this.state.customerDB.filter((data: any) => this.state.filterCustomer.includes(data.id))
        const finalSelectedCustomerResponse = selectCustomer.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedCustomerResponse) !== JSON.stringify(this.state.customerSelected)) {
          this.setState({
            customerSelected: finalSelectedCustomerResponse,
          });
        }
      }
      if (this.state.filterRegionId) {
        const selectRegionId = this.state.locationRegionDB.filter((data: any) => this.state.filterRegionId.includes(data.id))
        const finalSelectedRegionIdResponse = selectRegionId.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedRegionIdResponse) !== JSON.stringify(this.state.locationRegionSelected)) {
          this.setState({
            locationRegionSelected: finalSelectedRegionIdResponse,
          });
        }
      }
      if (this.state.filterMarketSegment) {
        const selectMarketSegment = this.state.marketSegmentDB.filter((data: any) => this.state.filterMarketSegment.includes(data.id))
        const finalSelectedMarketSegmentResponse = selectMarketSegment.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedMarketSegmentResponse) !== JSON.stringify(this.state.marketSegmentSelected)) {
          this.setState({
            marketSegmentSelected: finalSelectedMarketSegmentResponse,
          });
        }
      }
      if (this.state.filterBusinessModel) {
        const selectBusinessModel = this.state.businessModelDB.filter((data: any) => this.state.filterBusinessModel.includes(data.id))
        const finalSelectedBusinessModelResponse = selectBusinessModel.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedBusinessModelResponse) !== JSON.stringify(this.state.businessModelSelected)) {
          this.setState({
            businessModelSelected: finalSelectedBusinessModelResponse,
          });
        }
      }
      if (this.state.filterManager) {
        const selectManager = this.state.managerDB.filter((data: any) => this.state.filterManager.includes(data.id))
        const finalSelectedManagerResponse = selectManager.map((x: any) => {
         
          return {
            value: x.id,
            label: x.firstName
          }
        })
        if (JSON.stringify(finalSelectedManagerResponse) !== JSON.stringify(this.state.deploymentManagerSelected)) {
          this.setState({
            deploymentManagerSelected: finalSelectedManagerResponse,
          });
        }
      }
      if (this.state.filterType) {
        const selectType = this.state.deployTypeDB.filter((data: any) => this.state.filterType.includes(data.id))
        const finalSelectedTypeResponse = selectType.map((x: any) => {
         
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedTypeResponse) !== JSON.stringify(this.state.locationTypeSelected)) {
          this.setState({
            locationTypeSelected: finalSelectedTypeResponse,
          });
        }
      }
      if (this.state.filterProvider) {
        const selectProvider = this.state.filterProviderDB.filter((data: any) => this.state.filterProvider.includes(data.id))
        const finalSelectedProviderResponse = selectProvider.map((x: any) => {
       
          return {
            value: x.id,
            label: x.title
          }
        })
        if (JSON.stringify(finalSelectedProviderResponse) !== JSON.stringify(this.state.filterProviderSelected)) {
          this.setState({
            filterProviderSelected: finalSelectedProviderResponse,
          });
        }
      }
    }
    
  }
  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Locations List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#location-filter"
                aria-expanded="false"
                aria-controls="location-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Locations List
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="collapse" id="location-filter" aria-labelledby="location-filter">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Created At (From Date)</label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Created At (To Date)</label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customers</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        customerSelected: selected.value,
                        filterCustomer: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.customerSelected}
                    options={Array.from(this.state.customerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Distributors</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        distributorSelected: selected,
                        filterDistributor: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.distributorSelected}
                    options={Array.from(this.state.distributorDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Location Region
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationRegionSelected: selected,
                        filterRegionId: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationRegionSelected}
                    options={Array.from(this.state.locationRegionDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Market Segment
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        marketSegmentSelected: selected,
                        filterMarketSegment: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.marketSegmentSelected}
                    options={Array.from(this.state.marketSegmentDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Business Model
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        businessModelSelected: selected,
                        filterBusinessModel: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.businessModelSelected}
                    options={Array.from(this.state.businessModelDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Deployment Manager
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        deploymentManagerSelected: selected,
                        filterManager: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.deploymentManagerSelected}
                    options={Array.from(this.state.managerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Location Type
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationTypeSelected: selected,
                        filterType: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationTypeSelected}
                    options={Array.from(this.state.deployTypeDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Filter Provider
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        filterProviderSelected: selected,
                        filterProvider: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.filterProviderSelected}
                    options={Array.from(this.state.filterProviderDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Location Name</label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterName"
                    value={this.state.filterName}
                    onChange={(event: any) => {
                      this.setState({
                        filterName: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Status</label>
                  <Select
                    ref={this.state.resetStatus}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterStatus"
                    defaultValue={{
                      label: this.getLabelForStatus(this.state.filterStatus),
                      value: this.state.filterStatus
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterStatus: event.value,
                      });
                    }}
                    options={Array.from(this.state.statusDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Pending Installation</label>
                  <Select
                    ref={this.state.resetPendingInstallation}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterPendingInstallation"
                    defaultValue={this.state.installationSelected}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        installationSelected: event.value,
                        filterPendingInstallation: event.value,
                      });
                    }}
                    options={Array.from(this.state.isInstallationDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                {/* <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/maps/map008.svg"
                    className="svg-icon-4 me-1"
                  />
                  Locations
                </h3> */}
                <Columns
                  columnData={this.state.columnData}
                  selectedColumns={this.state.selectedColumns}
                  handleColumnDataSelect={this.handleColumnDataSelect}
                  handleColumnDataSubmit={this.handleColumnDataSubmit}
                />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  onClick={this.handleDownloadExcel}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                >
                  {this.state.downloadLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.downloadLoading && (
                    <span className="indicator-label"> <KTSVG
                      path="/media/icons/duotune/files/fil021.svg"
                      className="svg-icon-4 me-2"
                    />
                      Export Excel</span>
                  )}

                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="table-responsive display p-0 pb-2 fs-6"
                  id="data-table"
                >
                  <table
                    id="locationTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="locationId text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location ID
                          <Tooltip anchorSelect=".locationId" className="tooltip-list " data-placement="top">
                          Unique Smoodi identifier automatically created by the software
                          </Tooltip>
                        </th>
                        <th className="customerName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Customer Name
                          <Tooltip anchorSelect=".customerName" className="tooltip-list " data-placement="top">
                          Displays the name of the parent company or brand associated with the location
                          </Tooltip>
                        </th>
                        <th className="locationName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Name
                          <Tooltip anchorSelect=".locationName" className="tooltip-list " data-placement="top">
                          Displays the name of the specific branch or location of the customer
                          </Tooltip>
                        </th>
                        <th className="locationAddress text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Address
                          <Tooltip anchorSelect=".locationAddress" className="tooltip-list " data-placement="top">
                          Shows the physical address of the customer's specific location.
                          </Tooltip>
                        </th>
                        <th className="locationRegion text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Region
                          <Tooltip anchorSelect=".locationRegion" className="tooltip-list " data-placement="top">
                          Displays the region in which this location is situated, as assigned by smoodi divisions
                          </Tooltip>
                        </th>
                        <th className="businessModel text-capitalize font-weight-bolder opacity-7 ps-2">
                          Business Model
                          <Tooltip anchorSelect=".businessModel" className="tooltip-list " data-placement="top">
                          Select 'Sold' if the customer has purchased the machine outright or financed it through a third party. Choose 'Leased' if the customer is making monthly payments directly to Smoodi or received the machine for free
                          </Tooltip>
                        </th>
                        <th className="marketSegment text-capitalize font-weight-bolder opacity-7 ps-2">
                          Market Segment
                          <Tooltip anchorSelect=".marketSegment" className="tooltip-list " data-placement="top">
                          Identify in which market this customer belongs too
                          </Tooltip>
                        </th>
                        <th className="deploymentManager text-capitalize font-weight-bolder opacity-7 ps-2">
                          Deployment Manager
                          <Tooltip anchorSelect=".deploymentManager" className="tooltip-list " data-placement="top">
                          The person within Smoodi responsible for overseeing this account, typically a regional manager."
                          </Tooltip>
                        </th>
                        <th className="distributer text-capitalize font-weight-bolder opacity-7 ps-2">
                          Distributor
                          <Tooltip anchorSelect=".distributer" className="tooltip-list " data-placement="top">
                          Indicates the distributor from which the customer is receiving smoodi cups
                          </Tooltip>
                        </th>
                        <th className="locationType text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Type
                          {/* <Tooltip anchorSelect=".locationType" className="tooltip-list " data-placement="top">
                          What capacity is the machine performing right now. 
                          </Tooltip> */}
                        </th>
                        <th className="filterProvider text-capitalize font-weight-bolder opacity-7 ps-2">
                          Filter Provider
                          <Tooltip anchorSelect=".filterProvider" className="tooltip-list " data-placement="top">
                          If the machine utilizes the store's main water filter, select 'Location'.
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Target Date
                        </th>
                        <th className="noOfBlender text-capitalize font-weight-bolder opacity-7 ps-2">
                          No.of Blenders
                          <Tooltip anchorSelect=".noOfBlender" className="tooltip-list " data-placement="top">
                          The number of smoodi machines at the location
                          </Tooltip>
                        </th>
                        <th className="noOfFreezers text-capitalize font-weight-bolder opacity-7 ps-2">
                          No.of Freezers
                          <Tooltip anchorSelect=".noOfFreezers" className="tooltip-list " data-placement="top">
                         The number of smoodi freezers at the location
                          </Tooltip>
                        </th>
                        <th className="samplingTime text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sampling Time
                          <Tooltip anchorSelect=".samplingTime" className="tooltip-list " data-placement="top">
                          The recommended hour of the day for holding a sampling event at the location, as determined by the regional manager in consultation with the location's staff.
                          </Tooltip>
                        </th>
                        <th className="samplingDays text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sampling Days
                          <Tooltip anchorSelect=".samplingDays" className="tooltip-list " data-placement="top">
                          The recommended day of the week for holding a sampling event at the location, as determined by the regional manager in consultation with the location's staff.
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Water Pressure
                        </th>
                        <th className="cratedAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                          <Tooltip anchorSelect=".cratedAt" className="tooltip-list " data-placement="top">
                          Displays the date and time when this location was first registered in the system
                          </Tooltip>
                        </th>
                        <th className="status text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                          <Tooltip anchorSelect=".status" className="tooltip-list " data-placement="top">
                          Select whether this location is currently active or inactive
                          </Tooltip>
                        </th>
                        <th className="action text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                          <Tooltip anchorSelect=".action" className="tooltip-list " data-placement="top">
                          Edit or change the status of the location
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dropdown-menu d-block mb-5 "
          aria-labelledby="dropdownMenuButton"
        >
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            id="context-menu"
          >
            <li>
              <button
                className="dropdown-item editBtn" id="edit_record_link"
              >
                <i className="fa fa-pencil fs-6"></i>{" "}
                <span className="ms-2">Edit</span>
              </button>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a href="javascript:void(0)" className="dropdown-item" id="view_record_link">
                <i className="fa fa-eye f-6"></i>
                <span className="ms-2"> View </span>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a href="javascript:void(0)" className="dropdown-item updatePrice" id="update_flavor_price">
                <i className="fa fa-dollar f-6"></i>
                <span className="ms-2"> Update price </span>
              </a>
            </li>

            <li>
             {/* eslint-disable-next-line */}
              <a href="javascript:void(0)" className="dropdown-item freezerInformation" id="freezer_information">
                <i className="fa fa-info-circle f-6"></i>
                <span className="ms-2"> Freezer Info.  </span>
              </a>
            </li>
          </ul>
        </div>


        {this.state.locationEditModal === true && (
          <EditLocation
            currentLocationId={this.state.currentLocationId}
            locationEditModal={this.state.locationEditModal}
            handleLocationClose={this.handleLocationClose}
            refreshTable={this.refreshTable}
          />)}

        {this.state.locationUpdatePriceModal === true && (
          <UpdatePriceLocation
            currentLocationId={this.state.currentLocationId}
            locationUpdatePriceModal={this.state.locationUpdatePriceModal}
            handleLocationFlavorPriceClose={this.handleLocationFlavorPriceClose}
            refreshTable={this.refreshTable}
          />)}

        {this.state.freezerInformationModal === true && this.state.currentLocationId && (
          <FreezerInformation
            currentLocationId={this.state.currentLocationId}
            freezerInformationModal={this.state.freezerInformationModal}
            handleFreezerClose={this.handleFreezerClose}
            refreshTable={this.refreshTable}
          />
        )}
        {this.state.changeReactivateModal === true && (
              <ReactivateLocationModal
                changeReactivateModal={this.state.changeReactivateModal}
                handleCloseReactivateLocation={this.handleCloseReactivateLocation}
                currentLocationId={this.state.currentLocationId}
                refreshTable={this.refreshTable}
              />
         )}
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </>
    );
  }
}

export default LocationsList;